import React, {useContext, useEffect, useState} from "react"
import { Router } from "@reach/router"
import Profile from "./profile"
import { PrivateRoute } from "../components/Auth"
import {FirebaseContext} from "../context"

const Users = () => {

  const { user, loading } = useContext(FirebaseContext)
 
  return (
    <Router basepath="/users">
      <PrivateRoute path="/profile" component={Profile}  />
    </Router>
  )
}

export default Users